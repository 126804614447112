import logo from './images/cristallodiluce.jpg';
import logohype from './images/hype.png';
import axios from 'axios';
import { connect } from 'mqtt';
import './App.css';

import { useState, useEffect } from 'react';

function App() {
  const [panels, setPanels] = useState([{ voltage: 12.34, current: 1.56 }, { voltage: 12.76, current: 1.78 }, { voltage: 11.84, current: 1.34 }]);
  const [weather, setWeather] = useState({});

  let client;

  useEffect(() => {
    getDashboard();
  }, []);

  async function getDashboard() {
    await axios.get('https://cristallo.hype-design.it/api/dashboard').then((v) => {
      setPanels(v.data.payload);
      setWeather(v.data.weather);
    }).catch((e) => console.log(e));
    if (!client || !client.connected) _connect();
  }

  const _connect = async () => {
    client = connect('wss://cristallo.hype-design.it', { username: 'cristallo', password: 'VI?/NMvBK0nH', port: 9002, protocol: 'wss', rejectUnauthorized: true, reconnectPeriod: 1000 });

    await new Promise((resolve) => client.once('connect', resolve));

    client.subscribe('stream/1');

    client.on('message', _onMessage);
  }

  const _onMessage = async (topic, message) => {
    try {
      const payload = JSON.parse(message.toString());

      setPanels(payload.payload);
      setWeather(payload.weather);

      return;
    } catch (e) {
      return;
    }
  }

  return (
    <div className="App">
      <div id='Body'>
        <img src={logo} style={{ width: '60%', marginTop: '5%' }} />
        <img src={logohype} style={{ width: '30%', marginTop: '5%', alignSelf: 'end' }} />
        <div id="Row">
          <p id="ValueTitle" style={{ marginTop: '7%', marginBottom: '5%' }}>Energia prodotta: {(panels[0].voltage * panels[0].current + panels[1].voltage * panels[1].current + panels[2].voltage * panels[2].current).toFixed(2) + ' W'}</p>
          <div id="ValueContainer">
            <p id="ValueTitle">Pannello 1</p>
            <p id="ValueText">Tensione: {(panels[0].voltage).toFixed(2) + ' V'}</p>
            <p id="ValueText"> Corrente: {(panels[0].current).toFixed(2) + ' A'}</p >
            <p id="ValueText"> Potenza: {(panels[0].voltage * panels[0].current).toFixed(2) + ' W'}</p >
          </div >
          <div id="ValueContainer">
            <p id="ValueTitle">Pannello 2</p>
            <p id="ValueText">Tensione: {(panels[1].voltage).toFixed(2) + ' V'}</p>
            <p id="ValueText"> Corrente: {(panels[1].current).toFixed(2) + ' A'}</p >
            <p id="ValueText"> Potenza: {(panels[1].voltage * panels[1].current).toFixed(2) + ' W'}</p >
          </div>
          <div id="ValueContainer">
            <p id="ValueTitle">Pannello 3</p>
            <p id="ValueText">Tensione: {(panels[2].voltage).toFixed(2) + ' V'}</p>
            <p id="ValueText"> Corrente: {(panels[2].current).toFixed(2) + ' A'}</p >
            <p id="ValueText"> Potenza: {(panels[2].voltage * panels[2].current).toFixed(2) + ' W'}</p >
          </div>
        </div >
        <div id="Row" style={{ marginTop: '0' }}>
          <p id="ValueTitle">AMBIENTE</p>
          <p id="ValueText">Temperatura: {weather.temperature}</p>
          <p id="ValueText">Umidità relativa: {weather.humidity}</p >
          <p id="ValueText">Giorno / Notte: {weather.isDay ? 'Giorno' : 'Notte'}</p >
          <p id="ValueText">Nuvolosità: {weather.cloudcover}</p >
        </div>
      </div >
    </div >
  );
}

export default App;
